<template>
  <div class="update-password" tabindex="0" @keypress.enter="save">
    <el-form :model="form"
             :rules="formRules"
             ref="updatePassForm"
             status-icon
             hide-required-asterisk
             label-width="90px"
             @submit.prevent>
      <el-form-item label="旧密码" prop="oldPassword">
        <el-input type="password" v-model="form.oldPassword" show-password autocomplete="off" size="medium"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input type="password" v-model="form.newPassword" show-password autocomplete="off" size="medium"></el-input>
      </el-form-item>
      <el-form-item label="确认新密码" prop="enterPassword">
        <el-input type="password" v-model="form.enterPassword" show-password autocomplete="off" size="medium"></el-input>
      </el-form-item>
    </el-form>
    <div class="footer">
      <el-button type="primary" size="small" @click="save">确认</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const validateEnterPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'));
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入的新密码不一致'));
      } else {
        callback();
      }
    };

    return {
      form: {
        oldPassword: "",
        newPassword: "",
        enterPassword: ""
      },

      formRules: {
        oldPassword: [
          {required: true, message: "请输入旧密码", trigger: "blur"}
        ],
        newPassword: [
          {required: true, message: "请输入新密码", trigger: "blur"}
        ],
        enterPassword: [
          {validator: validateEnterPass, trigger: "blur"}
        ]
      }
    }
  },
  methods: {
    save() {
      this.$refs["updatePassForm"].validate(async valid => {
        if (valid) {
          this.$store.commit('changeLoading', true)

          let sendData = {
            oldPassWord: this.form.oldPassword,
            newPassWord: this.form.newPassword
          }

          const res = await this.$api.Common.User.updatePassword(sendData).catch(() => {
            this.$message.error('修改失败')
            this.$store.commit('changeLoading', false)
            return false
          })

          if (+res.code !== 200) {
            this.$message.error('修改失败')
            this.$store.commit('changeLoading', false)
          } else {
            this.$message.success('修改成功')
            this.$emit('close')
          }

          this.$store.commit('changeLoading', false)
          // console.log(res)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./update-password";
</style>
